/* DataTable Header */
.datatable-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Search Input */
  .search-input {
    width: 25%;
    padding: 10px;
    margin-bottom: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
  }
  
  /* Page Size Select */
  .page-size-select {
    margin-right: 10px;
    border-radius: 5px;
    padding: 4px 8px;
  }
  
  /* No Data Found Message */
  .no-data {
    text-align: center;
    font-weight: bold;
    margin: 20px 0;
    color: #666;
  }
  
  /* Custom Table Styles */
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    background-color: #f8f8f8;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Table Headers */
  .custom-table th {
    background-color: #333;
    color: #fff;
    padding: 12px;
    text-align: left;
  }
  
  /* Table Rows */
  .custom-table td {
    border-bottom: 1px solid #e0e0e0;
    padding: 12px;
    text-align: left;
    background-color: #fff;
    color: #333;
  }
  
  /* Clickable Rows */
  .clickable-row {
    cursor: pointer;
  }
  
  /* Pagination */
  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
  }
  
  /* Pagination Buttons */
  .page-button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #333;
    background-color: transparent;
    color: #333;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .page-button.active {
    background-color: #333;
    color: #fff;
  }
  