.accordion-item {
    border: 1px solid #dee2e6; /* Add border */
    border-radius: 0.25rem; /* Add rounding */
    margin-bottom: 10px; /* Add spacing between accordion items */
    margin-top: 10px; /* Add spacing between accordion items */

}

.accordion-button {
    width: 100%;
    padding: 10px;
    text-align: left;
    background-color: #f8f9fa;
    border: none;
    border-bottom: 1px solid #dee2e6; /* Add bottom border for button */
    cursor: pointer;
}

.accordion-content {
    padding: 2px;
    /* Add top border for content */
}

.show {
    display: block !important;
}
.btn-group btn-group-toggle list-custom-class{
    padding: 50px;
}
.accordion-content {
    padding: 10px;
  }
  
  .accordion-table-wrapper {
    max-height: 700px; /* Adjust the maximum height as needed */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  .offcanvas-body {
    flex-grow: 1;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
    /* overflow-y: auto; */
}
  
