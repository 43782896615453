.image-view-container {
    /* height: 100vh; */
  }
  
  .left-section {
    padding: 20px;
    border-right: 1px solid #ccc;
    overflow-y: auto;
  }
  
  .divider {
    /* width: .5px;
    background-color: #ccc;
    height: 100vh; */
  }
  
  .right-section {
    padding: 20px;
    overflow-y: auto;
  }
  