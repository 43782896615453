
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"; 
 @import "https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700"; 
.App {
  display: flex;
  width: 100%;
  align-items: stretch;
  
  
}


body {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure the page takes the full height of the viewport */
  margin: 0; /* Remove default margin */
  overflow: hidden; /* Hide overflow to prevent double scrollbars */
  font-family: var(--font-family-sans-serif);
  

}

/* Create a wrapper for the NavBar and content */
.wrapper {
  display: flex;
  flex: 1; /* Allow the wrapper to grow and take remaining space */
  overflow-y: hidden; /* Hide vertical overflow */
}

/* a,
a:hover,
a:focus {
  color: #F8F9FC;
  text-decoration: none;
  transition: all 0.2s;
} */

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
/* Sidebar Styles */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background:  #5A5C69;
  color: #F8F9FC;
  margin-left: -250px;
  transition: all 0.7s;
  padding: 0; /* Remove padding to maximize content space */
  font-family: var(--font-family-sans-serif);

}

#nav-dropdown{
  color: gray;
  font-weight: bold;
  /* background-color: lightblue; */
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

.sidebar-header {
  background:  #5A5C69;
  color: #F8F9FC;
  padding: 1rem;
  display: flex; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  justify-content: space-between; /* Add space between button and heading */
  font-family: var(--font-family-sans-serif);

}
.image-class {
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
}

.sidebar-header h3 {
  font-size: 1.5rem;
  margin: 0;
}

/* .sidebar ul p {
  color: #fff;
  padding: 10px;
} */

.collapse.show,
.collapsing {
  background: #313b4c;
}

.nav-item {
  color: #adb5bd;
  font-weight: 300;
  padding: 10px 25px; /* Adjust padding for better spacing */
  display: flex; /* Center icon and text vertically */
  align-items: center;
  transition: background-color 0.2s;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}

.nav-link.active {
  color:  white;
  /* background: #F8F9FC !important; */
  width: 100%;
}
.nav-link {
  color: rgba(255, 255, 255, 0.8);
  --bs-nav-link-hover-color: 'none';
}
.nav-link:hover {
  color: 'inherit';
  --bs-nav-link-hover-color: 'none';

}

/* .nav-item:hover {
  background: #F8F9FC;
  color: #fff;
  transition: 0.2s;
  border-radius: 15;
} */

.nav-item .svg-inline--fa {
  color: inherit;
  margin-right: 10px; /* Add space between icon and text */
}

/* Content Styles */
.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  flex: 1;
}
.sidebar-header > button {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

.footer {
  background-color: #f8f9fa;
  padding: 20px 0;
  text-align: center;
}

@media only screen and (max-width: 575.98px) {
  body {
    overflow: hidden;
  }

  .content.is-open {
    /*margin-left: 100%;*/
    display: none;
    transition: all 0.5s;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
  }

  .sidebar.is-open > .sidebar-header button {
    display: block;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
  .card-row {
    display: grid;
    grid-template-columns: 1fr; /* 1 column */
    grid-gap: 16px;
  }

  /* On small screens: 2 columns */
  @media (min-width: 600px) {
    .card-row {
      grid-template-columns: repeat(2, 1fr); /* 2 columns */
    }
  }

  /* On medium screens and larger: 4 columns */
  @media (min-width: 960px) {
    .card-row {
      grid-template-columns: repeat(4, 1fr); /* 4 columns */
    }
  }
}
.py-3 {
  padding-top: 26rem !important;
}

:root {
  --font-family-sans-serif: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.beautiful-search-field {
  border-radius: 5px; /* Rounded corners */
  border: 1px solid #ccc; /* Light border */
  outline: none; /* Remove default outline */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: border-color 0.2s ease-in-out; /* Smooth border transition */
}

.beautiful-search-field:focus {
  border-color: #4CAF50; /* Green border on focus */
}

/* Add this to your CSS file */
.navbar .dropdown-toggle {
  font-size: 16px; /* Adjust font size as needed */
  color: #000; /* Adjust text color */
}

.navbar .dropdown-menu {
  min-width: 150px; /* Adjust width if needed */
}

.navbar .dropdown-item {
  font-size: 14px; /* Adjust font size for menu items */
}

.navbar .dropdown-item:hover {
  background-color: #f8f9fa; /* Adjust hover color */
}


/* CustomSwitch.css */
.custom-switch {
  display: flex;
  align-items: center;
}

.custom-switch .form-check-label {
  margin-left: 10px;
  font-size: 16px;
  color: #495057;
}

.custom-switch .form-check-input {
  width: 50px;
  height: 25px;
  position: relative;
  appearance: none;
  background: #ccc;
  outline: none;
  border-radius: 50px;
  transition: background 0.3s;
  cursor: pointer;
}

.custom-switch .form-check-input:checked {
  background: #4caf50;
}

.custom-switch .form-check-input:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 2.5px;
  left: 2.5px;
  background: #fff;
  transition: transform 0.3s;
}

.custom-switch .form-check-input:checked:before {
  transform: translateX(25px);
}


.file-download-container, .file-generation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.file-download-text, .file-generation-text {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.spinner-container {
  margin-left: 10px;
}

.progress-bar-container, .batch-progress {
  width: 100%;
  max-width: 300px;
  margin: 10px 0;
}

.batch-info {
  font-size: 14px;
  color: #666;
}
