.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .card-subtitle {
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .card-body {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  